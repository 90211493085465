// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1xAE3R13E-2iGLRQV3WU0z{font-size:2em}._3GBqw47PZRD0qBzaTNaMyf{display:inline-flex;list-style:none;padding-left:10px;padding-right:10px;margin:0px;margin-left:5px;margin-right:5px}", "",{"version":3,"sources":["webpack://./src/components/Line/Line.scss"],"names":[],"mappings":"AAAA,yBACI,aAAA,CAGJ,yBACI,mBAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".container {\n    font-size: 2em;\n}\n\n.list{\n    display: inline-flex;\n    list-style: none;\n    padding-left: 10px;\n    padding-right: 10px;\n    margin: 0px;\n    margin-left: 5px;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1xAE3R13E-2iGLRQV3WU0z",
	"list": "_3GBqw47PZRD0qBzaTNaMyf"
};
export default ___CSS_LOADER_EXPORT___;
