// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{display:flex;flex-direction:column;align-items:center}._1hpWpXtB2tM5sMdGyOvP1B{display:flex;flex-direction:column;align-items:center}", "",{"version":3,"sources":["webpack://./src/containers/LandingPage.scss"],"names":[],"mappings":"AAAA,KACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAGJ,yBACI,YAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":["body {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.frame {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": "_1hpWpXtB2tM5sMdGyOvP1B"
};
export default ___CSS_LOADER_EXPORT___;
