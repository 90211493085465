// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v8hKZzGjkyk_0sF9gdCx3{color:#fed149;width:100%;display:flex;margin-bottom:5px;justify-content:space-between;align-items:center}._168GW8YZeOpNP18LPBLEvx{font-size:3em}._1ydU6_jZBbWDsxtFWMGKlI{font-size:2em}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAMA,uBACI,aALK,CAML,UAAA,CACA,YAAA,CACA,iBAAA,CACA,6BAAA,CACA,kBAAA,CAEJ,yBACI,aAAA,CAEJ,yBACI,aAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.header {\n    color: $yellow;\n    width: 100%;\n    display: flex;\n    margin-bottom: 5px;\n    justify-content: space-between;\n    align-items: center;\n}\n.primary{\n    font-size: 3em;\n}\n.secondary{\n    font-size: 2em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "v8hKZzGjkyk_0sF9gdCx3",
	"primary": "_168GW8YZeOpNP18LPBLEvx",
	"secondary": "_1ydU6_jZBbWDsxtFWMGKlI"
};
export default ___CSS_LOADER_EXPORT___;
