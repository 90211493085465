export const AboutMe = {
    name: 'About-Me',
    type: 'building',
    position: {
        x: 200,
        y: 370,
    },
    dimension: {
        x: 220,
        y: 150
    },
    sprite: '/sprites/about-me-building-1.png',
    map: 'About-Me-Portal'
};
