export const ContactGithub = {
    name: 'Github',
    type: 'building',
    position: {
        x: 700,
        y: 330
    },
    dimension: {
        x: 320,
        y: 70
    },
    wordArrays: [
        ['Github:'],
        ['/protopaco']
    ],
    fontSize: '.7em',
    link: 'https://github.com/Protopaco'

};
