// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3kLo1lsqhLdeBccbY5OLr_{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/Eye/Eye.scss"],"names":[],"mappings":"AAAA,yBACI,iBAAA","sourcesContent":[".sprite{\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sprite": "_3kLo1lsqhLdeBccbY5OLr_"
};
export default ___CSS_LOADER_EXPORT___;
