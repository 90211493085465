// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1FZspyz9J37uvjuJk-yUiq{-ms-interpolation-mode:nearest-neighbor;image-rendering:-moz-crisp-edges;image-rendering:pixelated;position:absolute;display:flex;flex-direction:column;align-items:center;cursor:pointer}._3UuSnecIuJ0gPUBrTnnzgf{color:#fed149}a{text-decoration:none}._3sf69XjK4ozFVKhcYzHp0w{border:3px solid #8eb8e5;background-color:#282846;color:#fff;display:flex;flex-direction:column;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/Building/Building.scss"],"names":[],"mappings":"AAMA,yBACQ,uCAAA,CACI,gCAAA,CACA,yBAAA,CACJ,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CAER,yBACQ,aAfC,CAiBT,EACQ,oBAAA,CAER,yBACQ,wBAAA,CACA,wBAvBK,CAwBL,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.building{\n        -ms-interpolation-mode: nearest-neighbor;\n            image-rendering: -moz-crisp-edges;\n            image-rendering: pixelated;\n        position: absolute;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        cursor: pointer;\n}\n.name{\n        color: $yellow;\n}\na{\n        text-decoration: none;\n}\n.textcontainer {\n        border: 3px solid $lightBlue;\n        background-color: $background;\n        color: white;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"building": "_1FZspyz9J37uvjuJk-yUiq",
	"name": "_3UuSnecIuJ0gPUBrTnnzgf",
	"textcontainer": "_3sf69XjK4ozFVKhcYzHp0w"
};
export default ___CSS_LOADER_EXPORT___;
