// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3iIYBrKW5RWQULpsb_R3dy{position:absolute;height:30px;border:10px solid #fed149;margin:0px}.b6cwwGQe2hsgTJ6xQdpVb{color:#fed149;font-size:1.7em;height:30px;width:1300px;display:flex;justify-content:space-evenly}", "",{"version":3,"sources":["webpack://./src/components/MarqueeBox/MarqueeBox.scss"],"names":[],"mappings":"AAMA,yBACI,iBAAA,CACA,WAAA,CACA,yBAAA,CACA,UAAA,CAEJ,uBACI,aAXK,CAYL,eAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,4BAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.container{\n    position: absolute;\n    height: 30px;\n    border: 10px solid $yellow;\n    margin: 0px;\n}\n.text{\n    color: $yellow;\n    font-size: 1.7em;\n    height: 30px;\n    width: 1300px;\n    display: flex;\n    justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3iIYBrKW5RWQULpsb_R3dy",
	"text": "b6cwwGQe2hsgTJ6xQdpVb"
};
export default ___CSS_LOADER_EXPORT___;
