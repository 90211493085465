// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1vIXPJfKEwaQ1lqzIGEMmB{position:absolute;border:3px solid #fed149;color:#fed149;display:flex;justify-content:center;align-items:center;font-size:2em;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/BackButton/BackButton.scss"],"names":[],"mappings":"AAMA,yBACI,iBAAA,CACA,wBAAA,CACA,aAPK,CAQL,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.button{\n    position: absolute;\n    border: 3px solid $yellow;\n    color: $yellow;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 2em;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_1vIXPJfKEwaQ1lqzIGEMmB"
};
export default ___CSS_LOADER_EXPORT___;
