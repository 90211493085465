export const Career = {
    name: 'Career',
    type: 'building',
    position: {
        x: 900,
        y: 80
    },
    dimension: {
        x: 220,
        y: 150
    },
    sprite: '/sprites/career-building-1.png',
    map: 'Career-Portal'

};
