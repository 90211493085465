// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2zmZjDyHwz-hsfiaAwTPTJ{background-color:#fed149;position:absolute;z-index:1}", "",{"version":3,"sources":["webpack://./src/components/Walls/Walls.scss"],"names":[],"mappings":"AAMA,yBACI,wBALK,CAML,iBAAA,CACA,SAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.wall {\n    background-color: $yellow;\n    position: absolute;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wall": "_2zmZjDyHwz-hsfiaAwTPTJ"
};
export default ___CSS_LOADER_EXPORT___;
