// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1B6tWH5AF7bgUjLcEKG1s{background-color:#282846;height:800px;width:1250px;position:relative;top:0px;left:0px;display:block;margin-left:auto;margin-right:auto;margin-bottom:5%}", "",{"version":3,"sources":["webpack://./src/components/Engine/Engine.scss"],"names":[],"mappings":"AAMA,wBACI,wBANS,CAOT,YAAA,CACA,YAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.container {\n    background-color: $background;\n    height: 800px;\n    width: 1250px;\n    position:relative;\n    top: 0px;\n    left: 0px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1B6tWH5AF7bgUjLcEKG1s"
};
export default ___CSS_LOADER_EXPORT___;
