// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3aWaFYfHwxu7HotLIvc5i8{position:absolute;top:150px;left:240px;height:400px;width:700px;background-color:#5e7c9c;border:5px solid #fed149;color:#fff;padding-left:25px;padding-right:25px;display:flex;flex-direction:column;justify-content:center;align-content:center}._11qKrw-0abTnDFDdWkf_DZ{font-size:3em;text-align:center;margin-bottom:5px}._2iulFBaKvjLlvnY8e1qY7o{font-size:1.5em;list-style:none}._2miVphrMxwBytoQw0B8DNx{padding:5px}._3WK4Hvvw-HuK0THsNfGGbR{display:flex;justify-content:space-around;color:#fff}._1mEUbIRx0sLUX-DaGWX4uH{text-decoration:none;color:#fff;font-size:2em}", "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.scss"],"names":[],"mappings":"AAMA,yBACI,iBAAA,CACA,SAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA,CACA,wBAPO,CAQP,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,oBAAA,CAEJ,yBACI,aAAA,CACA,iBAAA,CACA,iBAAA,CAEJ,yBACI,eAAA,CACA,eAAA,CAEJ,yBACI,WAAA,CAEJ,yBACI,YAAA,CACA,4BAAA,CACA,UAAA,CAEJ,yBACI,oBAAA,CACA,UAAA,CACA,aAAA","sourcesContent":["\n$background: #282846;\n$yellow: #fed149;\n$lightBlue: #8eb8e5;\n$midBlue: #7197bd;\n$darkBlue: #5e7c9c;\n.container{\n    position: absolute;\n    top: 150px;\n    left: 240px;\n    height: 400px;\n    width: 700px;\n    background-color: $darkBlue;\n    border: 5px solid $yellow;\n    color: white;\n    padding-left: 25px;\n    padding-right: 25px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-content: center;\n}\n.title{\n    font-size: 3em;\n    text-align: center;\n    margin-bottom: 5px;\n}\n.list{\n    font-size: 1.5em;\n    list-style: none;\n}\n.listItem{\n    padding: 5px;\n}\n.links{\n    display: flex;\n    justify-content: space-around;\n    color: white;\n}\n.aTag {\n    text-decoration: none;\n    color: white;\n    font-size: 2em\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3aWaFYfHwxu7HotLIvc5i8",
	"title": "_11qKrw-0abTnDFDdWkf_DZ",
	"list": "_2iulFBaKvjLlvnY8e1qY7o",
	"listItem": "_2miVphrMxwBytoQw0B8DNx",
	"links": "_3WK4Hvvw-HuK0THsNfGGbR",
	"aTag": "_1mEUbIRx0sLUX-DaGWX4uH"
};
export default ___CSS_LOADER_EXPORT___;
